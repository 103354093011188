import React, { useEffect, useState } from 'react';
import './Adminpanel.css';
import { Link, Outlet } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Adminpanel() {

  const { setBardis, isadmin, setIsadmin, encryptData, key } = useAuth();
  const [navdis, setNavdis] = useState('none');
  const [navind, setNavind] = useState(0);
  const [form, setForm] = useState({
    username: '',
    password: ''
  });

  useEffect(()=> window.scrollTo(0, 0), []);

  useEffect(()=> {
    if(localStorage.getItem("adlocprodat")){
      setIsadmin(true);
    }
  }, [setIsadmin]);

  useEffect(()=> {
    if(window.innerWidth < 800){
      setNavdis('none');
    }else{
      setNavdis('flex');
    }
  }, [])

  useEffect(() => {
    setBardis(true);
    return () => {
      setBardis(false);
    }
  }, [setBardis]);

  const loginFunc = () => {
    if(form.username && form.password){
      fetch(`https://shahstore.co.in/api/adminlogin`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(form)
      }).then(res => res.json()).then(data => {
        console.log(data);
        if(data.success){
          setIsadmin(true);
          const secdata = encryptData(data?.pro, key);
          localStorage.setItem('adlocprodat', secdata);
        }else{
          console.log(data);
        }
      }).catch(err => console.log('error occured while logging in as admin ', err));
    }
  }

  const closeFunc = () => {
    if(window.innerWidth < 800){
      setNavdis('none');
    }
  }

  return (
    <div className='main-dashboard my-2'>
      {
        isadmin? (
          <div className="m-0 p-0">
            <div className="container-fluid main-dashboard-contianer row align-items-start m-0 p-0">
              <div className="col-md-2 dash-nav rounded-brand bg-brand-light flex-column justify-content-between" style={{display: navdis}}>
                <div className="m-0 p-0">
                  <Link to='/' className='nav-link fs-3 body-font-extrabold mt-3'>Shah</Link>
                  <ul className="dash-ul p-0 m-0 mt-4">
                    <li className={`fs-6 mb-3 rounded-brand px-3 ${navind === 0? 'bg-white p-2':''}`} onClick={()=> {setNavind(0); closeFunc()}}><Link to='/dashboard' className='nav-link py-1 body-font-medium'><i className="fas fa-th-large me-2"></i>DASHBOARD</Link></li>
                    <li className={`fs-6 mb-3 rounded-brand px-3 ${navind === 1? 'bg-white p-2':''}`} onClick={()=> {setNavind(1); closeFunc()}}><Link to='products' className='nav-link py-1 body-font-medium'><i className="fas fa-box me-2"></i>PRODUCTS</Link></li>
                    <li className={`fs-6 mb-3 rounded-brand px-3 ${navind === 4? 'bg-white p-2':''}`} onClick={()=> {setNavind(4); closeFunc()}}><Link to='upload' className='nav-link py-1 body-font-medium'><i className="fas fa-plus-square me-2"></i>UPLOAD</Link></li>
                    <li className={`fs-6 mb-3 rounded-brand px-3 ${navind === 5? 'bg-white p-2':''}`} onClick={()=> {setNavind(5); closeFunc()}}><Link to='admins' className='nav-link py-1 body-font-medium'><i className="fas fa-users-cog me-2"></i>ADMINS</Link></li>
                  </ul>
                </div>
                <p className="mb-0 mt-auto body-font-light cursor-pointer px-3" onClick={() => {localStorage.clear(); window.location.reload()}}><i className="fas fa-power-off me-2"></i>Log out</p>
              </div>
              <div className="col-md-10 success p-0 pt-4">
                <Outlet />
              </div>
            </div>
            <div className="nav-icon-wrap">
              <div className="ad-ham-wrap" onClick={()=> setNavdis(navdis === 'flex'? 'none': 'flex')}>
                <div className="ham d-flex">
                  <div className="little-one"></div>
                  <div className="big-one"></div>
                </div>
                <div className="large-one"></div>
              </div>
            </div>
          </div>
        ): (
          <div className="login-form-wrap p-2 d-flex align-items-center justify-content-center">
            <div className="ad-form-wrap bg-brand-light rounded-brand p-3 border border-dark">
              <input type="text" name="username" id="username" className='form-control p-2 border border-dark rounded-brand-sm outline-none shadow-none mb-3' placeholder='Enter your username' value={form?.username} onChange={(e)=> setForm(x=> ({...x, username: e.target.value}))} />
              <input type="password" name="password" id="password" className='form-control p-2 border border-dark rounded-brand-sm outline-none shadow-none mb-3' placeholder='Enter your password' value={form?.password} onChange={(e)=> setForm(x=> ({...x, password: e.target.value}))} />
              <div className="text-center">
                <button className="btn btn-dark body-font-light letter-spacing px-5" onClick={loginFunc}>LOGIN</button>
              </div>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default Adminpanel;