import React, { useEffect, useState } from 'react';
import './Products.css';
import Productcard from '../../Components/Productcard/Productcard';
// import { useAuth } from '../../Context/Authcontext';

function Products() {

    // const { splitFunc } = useAuth();
    const [allpro, setAllpro] = useState([]);

    useEffect(() => {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            if(data.success){
                setAllpro(data.pro);
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the products in products page ', err));
    }, []);

  return (
    <div className='main-products'>
        <div className="container-fluid">
            <h2 className="body-font-bold mb-4 mt-3 fs-3">All Products</h2>
            <div className="row p-0 m-0">
                {
                    allpro?.map((pro, ind) => (
                        <div key={ind} className="col-md-4 col-6 mb-4 p-1">
                           <Productcard coverimg={pro.images[0]} hoverimg={pro.images[1]} tag={pro.status} saletxt={pro.spinningtxt} name={pro.name} price={pro.discountprice} preprice={pro.price} id={pro.proid} />
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
  )
}

export default Products