import React from 'react';
import './Categorycard.css';
// import { Link } from 'react-router-dom';

function Categorycard(props) {
  return (
    <div className='main-categorycard'>
        {/* <Link to={`/product/${props?.cate}`} className='nav-link'> */}
            <div className="category-card d-flex flex-column align-items-center">
                <div className="cat-img-wrap d-flex justify-content-center align-items-center bg-brand-light p-0">
                    <img src={props?.img} alt="category icon" className="img-fluid" />
                </div>
                <div className="cat-text-wrap mt-2 px-1">
                    <p className="mb-0 cate-name fs-sm body-font-semibold text-center text-capitalize w-100">{props?.name}</p>
                    {/* <p className="mb-0 cate-count fs-sm text-muted body-font-light text-center">{props?.stock}+ Products</p> */}
                </div>
            </div>
        {/* </Link> */}
    </div>
  )
}

export default Categorycard;