import React, { useEffect, useState } from 'react';
import './Login.css';
import { useAuth } from '../../Context/Authcontext';
import { useNavigate } from 'react-router-dom';

function Login() {

    const { login } = useAuth();
    const navigate = useNavigate();

    useEffect(() => window.scrollTo(0, 0), []);

    const [errormsg, setErrormsg] = useState("");
    const [successmsg, setSuccessmsg] = useState("");
    const [loaderdis, setLoaderdis] = useState("none");
    const [formdata, setFormdata] = useState({
        number: "",
        password: ""
    });

    useEffect(()=> console.log(formdata), [formdata]);

    const signupFunc = () => {
        let isReady = false;

        if(formdata?.number === "" || formdata?.number?.length !== 10){
            setErrormsg("Please provide a valid phone number");
        }else if(formdata?.password === "" || formdata?.password?.length < 8){
            setErrormsg("Your password should contain at least 8 characters");
        }else{
            isReady = true
        }

        if(isReady){
            setLoaderdis("flex");
            fetch(`https://shahstore.co.in/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': "application/json"
                },
                body: JSON.stringify(formdata)
            }).then(res => res.json()).then(data => {
                console.log(data);
                if(data.success){
                    setSuccessmsg("Logged in successfully");
                    setLoaderdis("none");
                    login(data.pro);
                    navigate(-1);
                }else{
                    setErrormsg(data.msg);
                    setLoaderdis("none");
                    console.log(data);
                }
            }).catch(err => console.log('error occured while signing up ', err));
        }
    }

    const crossFunc = () => {
        setSuccessmsg("");
        setErrormsg("");
    }

    useEffect(() => {
        setTimeout(() => {
            setErrormsg("");
            setSuccessmsg("");
        }, 3000);
    }, [errormsg, successmsg])

  return (
    <div className='main-signup py-5'>
        <div className="container">
            <div className="form-container shadow py-4 pb-4 px-3 mx-auto mb-5">
                <h2 className="fs-4 text-center body-font-extrabold mb-4">Login</h2>
                <p className="mb-2 body-font-medium fs-5 ps-2">Phone number</p>
                <input type="tel" name='number' id='number' className='form-control shadow-none outline-none rounded-brand border border-dark mb-4' value={formdata?.number} onChange={(e)=> setFormdata((x)=> ({...x, number: e.target.value}))} placeholder='Enter your phone number'/>
                <p className="mb-2 body-font-medium fs-5 ps-2">Password</p>
                <input type="password" name='password' id='password' className='form-control shadow-none outline-none rounded-brand border border-dark mb-4' value={formdata?.password} onChange={(e)=> setFormdata((x)=> ({...x, password: e.target.value}))} placeholder='Create password'/>
                <p className="mb-0 px-2 mt-5">
                    <button className='btn btn-dark px-4 py-2 rounded-pill letter-spacing w-100 fs-5' onClick={signupFunc}>LOG IN</button>
                </p>
            </div>
        </div>

        <div className="error-pop-wrap p-2" style={{display: errormsg === ""? "none": "block"}}>
            <div className="popError-wrap mx-auto px-2 py-1 border-bottom border-danger border-2 shadow">
                <div className="bg-white d-flex align-items-center justify-content-between px-2 py-3">
                    <p className="mb-0 text-danger body-font-semibold ">{errormsg}</p>
                    <p className="mb-0 p-0 text-danger px-3 cursor-pointer" onClick={crossFunc}><i className="fas fa-times"></i></p>
                </div>
            </div>
        </div>

        <div className="error-pop-wrap p-2" style={{display: successmsg === ""? "none": "block"}}>
            <div className="popError-wrap mx-auto px-2 py-1 border-bottom border-success border-2 shadow">
                <div className="bg-white d-flex align-items-center justify-content-between px-2 py-3">
                    <p className="mb-0 text-success body-font-semibold ">{successmsg}</p>
                    <p className="mb-0 p-0 text-success px-3 cursor-pointer" onClick={crossFunc}><i className="fas fa-times"></i></p>
                </div>
            </div>
        </div>

        <div className="full-overlay" style={{display: loaderdis}}>
            <div className="loader-wrap rounded-brand p-4">
                <div className="loader-roundedr"></div>
            </div>
        </div>

    </div>
  )
}

export default Login;