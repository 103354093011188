import React, { useEffect, useState } from 'react';
import './Leads.css';
import { Link } from 'react-router-dom';
// import { Link } from 'react-router-dom';

function Leads() {

    const [allleads, setAllleads] = useState([]);
    const [allids, setAllids] = useState([]);
    const [allqua, setAllqua] = useState([]);
    const [allnam, setAllnam] = useState([]);
    const [isstate, setIsstate] = useState(null);
    const [loaderdis, setLoaderdis] = useState('none');

    useEffect(() => {
        fetch(`https://shahstore.co.in/api/allorders`).then(res => res.json()).then(data => {
            if(data.success){
                console.log(data);
                setAllleads(data.pro.reverse());
            }else{
                console.log(data);
            }
        }).catch(err => console.log('error occured while getting all the leads ', err));
    }, []);

    useEffect(() => {
        if(allleads?.length > 0){
            const allproids = [];
            const allproqua = [];
            const allpronam = [];
            allleads.forEach((led) => {
                const smids = [];
                const smqua = [];
                const smnam = [];
                led?.products.forEach(pro => {
                    smids.push(pro.proid);
                    smqua.push(pro.quantity);
                    smnam.push(pro.proname);
                });
                allproids.push(smids);
                allproqua.push(smqua);
                allpronam.push(smnam);
            });
            console.log(allproids, allproqua);
            setAllids(allproids);
            setAllqua(allproqua);
            setAllnam(allpronam);
        }
    }, [allleads])

    const dateFormatter = (date) => {
        const newDate = new Date(date);
        const formatedDate = `${newDate.getDate()}-${newDate.getMonth()}-${newDate.getFullYear()}, ${newDate.getHours()}:${newDate.getMinutes()}`
        return formatedDate;
    }

    const changeState = (txt, id) => {
        setLoaderdis('flex')
        const state = txt.toLowerCase();
        console.log(state);
        fetch(`https://shahstore.co.in/api/orderstate/${id}/${state}`).then(res => res.json()).then(data => {
            if(data.success){
                setLoaderdis('none');
                setIsstate(null);
                window.location.reload();
            }else{
                console.log('success is not true ', data);
                setLoaderdis('none');
                alert(data.msg);
            }
        }).catch(err => console.log("error occured while changing the state of the order ", err));
    }

  return (
    <div className='main-leads'>
        <div className="container-fluid">
            <h2 className="body-font-bold fs-3 mb-4 mt-3">Orders</h2>
            <div className="row p-2 justify-content-between">
                    <div className="col-md-3">
                        <p className="mb-0 body-font-semibol text-muted">USER</p>
                    </div>
                    <div className="col-md-1">
                        <p className="mb-0 body-font-semibol text-muted">QTY</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">PRODUCT LINK</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">ORDER ID</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">DATE & TIME</p>
                    </div>
                    <div className="col-md-2">
                        <p className="mb-0 body-font-semibol text-muted">STATE</p>
                    </div>
                </div>
            {
                allleads?.map((led, ind) => (
                    <div key={ind} className="row p-2 justify-content-between py-3 lead-card">
                        <div className="col-md-3">
                            <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">User:</span>{led?.usernumber}, <br /> {led?.address}</p>
                        </div>
                        <div className="col-md-1">
                            <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Quantity:</span>{allqua[ind]?.join(', ')}</p>
                        </div>
                        <div className="col-md-2">
                            <div className="text-primary mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Product Link:</span>
                                <div className="d-flex flex-wrap">
                                    {
                                        allids[ind]?.map((id, indsm) => (
                                            <Link key={indsm} to={`/${id}/${allnam[ind][indsm]?.replace(/ /g, '_')?.replace('/', '-')}`} className='nav-link d-inline text-primary'>{id}, </Link>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <p className="mb-0 body-font-semibol text-capitalize"><span className="mob-order text-muted me-3">Order ID:</span>{led?.orderid}</p>
                        </div>
                        <div className="col-md-2">
                            <p className="mb-0 body-font-semibol"><span className="mob-order text-muted me-3">Order date:</span>{dateFormatter(led?.orderdate)}</p>
                        </div>
                        <div className="col-md-2 status-wrap">
                            <p className="mb-0 body-font-semibol cursor-pointer px-2 py-1 border d-flex justify-content-between align-items-center text-capitalize" onClick={()=> setIsstate(isstate === ind? null: ind)}><span className="mob-order text-muted me-3">Status:</span>{led?.status? led?.status: 'Pending'} <i className="fas fa-chevron-down ms-2"></i></p>
                            <div className={`hidden-state ${isstate === ind? 'd-block': 'd-none'}`}>
                                <p className="mb-0 py-1 bg-light px-3 text-capitalize border-bottom" onClick={(e) => changeState(e.target.innerText, led?.orderid)}>ordered</p>
                                <p className="mb-0 py-1 bg-light px-3 text-capitalize border-bottom" onClick={(e) => changeState(e.target.innerText, led?.orderid)}>packed</p>
                                <p className="mb-0 py-1 bg-light px-3 text-capitalize border-bottom" onClick={(e) => changeState(e.target.innerText, led?.orderid)}>dispatched</p>
                                <p className="mb-0 py-1 bg-light px-3 text-capitalize border-bottom" onClick={(e) => changeState(e.target.innerText, led?.orderid)}>delivered</p>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>

        <div className="full-overlay" style={{display: loaderdis}}>
            <div className="loader-wrap rounded-brand p-4">
                <div className="loader-roundedr"></div>
            </div>
        </div>

    </div>
  )
}

export default Leads;