import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className="main-footer container-fluid pt-5 pb-2 bg-brand-light-brown">
        <div className="container">
            <div className="row text-white">
                <div className="col-lg-3 col-md-6 mb-5">
                    <div className="footer-logo-wrap">
                        <Link to="/"><img src="/assets/logo-white.png" alt="logo" className="img-fluid" width={170} /></Link>
                    </div>
                    <p className="mb-0 body-font-light lh-sm mt-4 fs-sm">
                        Discover timeless style for everyone at Shah Store – your destination for quality watches.
                    </p>
                    <div className="mt-4 d-flex align-items-center">
                        <a href="https://www.instagram.com/shah.store_72?igsh=MWE5M2hkNXg3anBvNQ==" target='_blank' rel='noreferrer' className="nav-link"><p className="mb-0 footer-social-icon p-3 me-3"><i className="fab fa-instagram fs-5"></i></p></a>
                        <a href="https://youtube.com/@shahsstoree?si=MFYGbDuNezk1Vef-" target='_blank' rel='noreferrer' className="nav-link"><p className="mb-0 footer-social-icon p-3 me-3"><i className="fab fa-youtube fs-5"></i></p></a>
                        <a href='https://wa.me/+919082874084' className="nav-link mb-0 footer-social-icon p-3 me-0"><i className="fab fa-whatsapp fs-5"></i></a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    <h2 className="fs-4 body-font-bold">Navigation Links</h2>
                    <Link to='/' className='nav-link w-max-content'><p className='mb-2'>Home</p></Link>
                    <Link to='/product' className='nav-link w-max-content'><p className='mb-2'>Products</p></Link>
                    <Link to='/terms-and-conditoins' className='nav-link w-max-content'><p className='mb-2'>Terms & Conditons</p></Link>
                    <Link to='/privacy-policy' className='nav-link w-max-content'><p className='mb-2'>Privacy Policy</p></Link>
                    <Link to='/disclaimer' className='nav-link w-max-content'><p className='mb-2'>Disclaimer</p></Link>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    <h2 className="fs-4 body-font-bold">Categories</h2>
                    <Link to='/product/men-watch' className='nav-link w-max-content'><p className='mb-2'>Men Watch</p></Link>
                    <Link to='/product/women-watch' className='nav-link w-max-content'><p className='mb-2'>Women Watch</p></Link>
                    <Link to='/product/celebrity-watch' className='nav-link w-max-content'><p className='mb-2'>Celebrity Watch</p></Link>
                    <Link to='/product/swizz-eta-watch' className='nav-link w-max-content'><p className='mb-2'>Swizz ETA Watch</p></Link>
                </div>
                <div className="col-lg-3 col-md-6 mb-5">
                    <h2 className="fs-4 body-font-bold">Contact Details</h2>
                    <a href='/' className='nav-link w-max-content'><p className='mb-2'><i className="fas fa-map-marker-alt me-2"></i>Mumbai, Maharashtra, India.</p></a>
                    <a href='tel:+919082874084' className='nav-link w-max-content'><p className='mb-2'><i className="fas fa-phone-alt me-2"></i>+91 90828 74084</p></a>
                    <a href='https://wa.me/+919082874084' className='nav-link w-max-content'><p className='mb-2'><i className="fab fa-whatsapp me-2"></i>+91 90828 74084</p></a>
                    <a href='mailto:shahstore@gmail.com' className='nav-link w-max-content'><p className='mb-2'><i className="fas fa-envelope me-2"></i>shahstore@gmail.com</p></a>
                </div>
            </div>
        </div>
        <div className="container mb-0 pt-4 border-top d-flex flex-wrap align-items-center justify-content-between text-white">
            <p className="mb-2"><span className="head-font-bold">Shah Store</span> © 2024 All Right Reserved</p>
            <p className="mb-2">Made by <a href='https://scalemax.co.in/' target='_blank' rel='noreferrer' className='nav-link text-warning body-font-bold d-inline'>Scalemax</a></p>
        </div>
    </div>
  )
}

export default Footer;