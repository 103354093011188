import React, { useEffect } from 'react';
import './PrivacyPolicy.css';

function PrivacyPolicy() {

    useEffect(()=> window.scrollTo(0, 0), []);

  return (
    <div className='container my-5'>
        <h2 className="body-font-bold mb-4 fs-3">Privacy Policy</h2>
        <p className="mb-4">
        Shah we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website, <a href="Shah.netlify.app" target='_blank' className="nav-link d-inline text-primary">www.Shah.com</a>, and purchase our products. By accessing or using our site, you agree to this Privacy Policy.
        </p>
        <div className="content">
            <ol className='m-0'>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Information We Collect</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Personal Information:</span> When you make a purchase or create an account on our website, we collect personal information such as your name, email address, phone number, shipping address, billing address, and payment details.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Non-Personal Information:</span> We may collect non-personal information, such as your browser type, IP address, device type, and browsing patterns, to improve your experience on our site.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Cookies:</span> Our website uses cookies to enhance your browsing experience, remember your preferences, and analyze site traffic. You can menage cookie preferences through your browser settings.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">How We Use Your Information</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>To Process Transactions:</span> We use your personal information to process and fulfill your orders, including shipping products to your address and processing payment transactions.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>To Communicate with You:</span> We may use your contact information to send you order updates, respond to inquiries, or provide customer support. With your consent, we may also send promotional emails and special offers.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>To Improve Our Services:</span> We analyze non-personal information to better understand user behavior and preferences, allowing us to improve our website and services.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>To Comply with Legal Obligations:</span> We may use your information to comply with legal requirements, such as tax obligations, and to enforce our terms and policies.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">How We Share Your Information</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Service Providers:</span> We may share your information with third-party service providers who assist us in operating our website, processing payments, fulfilling orders, and conducting marketing activities. These providers are obligated to protect your information and use it only for the services they provide.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Legal Requirements:</span> We may disclose your information if required to do so by law or in response to valid legal requests from public authorities.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Business Transfers:</span> In the event of a merger, acquisition, or sale of all or part of our assets, your information may be transferred to the new owners as part of the transaction.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Your Rights</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Access and Correction:</span> You have the right to access and update your personal information by logging into your account on our website. If you require assistance, please contact us at email@gmail.com.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Opt-Out:</span> You can opt out of receiving promotional emails from us by following the unsubscribe instructions included in the emails or by contacting us directly. Please note that you will still receive transactional emails related to your purchases.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Data Deletion:</span> You have the right to request the deletion of your personal information. To do so, please contact us at email@gmail.com. We will take reasonable steps to delete your data, except where we are required to retain it for legal or business purposes.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Security of Your Information</p>
                    <p className="text-muted">
                        We implement industry-standard security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Third-Party Links</p>
                    <p className="text-muted">
                        Our website may contain links to third-party websites that are not operated by us. We are not responsible for the privacy practices or the content of these external sites. We encourage you to review the privacy policies of any third-party websites you visit.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Children's Privacy</p>
                    <p className="text-muted">
                        Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we become aware that we have collected personal information from a child under 13, we will promptly delete it. If you believe we might have any information from or about a child under 13, please contact us at email@gmail.com.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">International Data Transfers</p>
                    <p className="text-muted">
                        If you are accessing our website from outside India, please be aware that your information may be transferred to, stored, and processed in India where our servers are located and our central database is operated. By using our services, you consent to any such transfer of information outside your country.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Changes to This Privacy Policy</p>
                    <p className="text-muted">
                        We may update this Privacy Policy from time to time to reflect changes in our practices or for other operational, legal, or regulatory reasons. Any changes will be posted on this page with an updated "Last Updated" date. We encourage you to review this Privacy Policy periodically.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Contact Information</p>
                    <p className="text-muted">
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </p>
                    <p className="mb-0">Shah Customer Service</p>
                    <p className="mb-0">Email: <a href="mailto:email@gmail.com" className="nav-link d-inline text-primary">email@gmail.com</a></p>
                    <p className="mb-0">Phone no.: <a href="tel:+919082874084" className="nav-link d-inline text-primary">+91 90828 74084</a></p>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default PrivacyPolicy