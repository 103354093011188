import React from 'react';
import './Procard.css';
import { Link } from 'react-router-dom';

function Procard(props) {

    const nameFormatter = (txt) => {
        if(window.innerWidth < 800){
            return txt?.length > 11? txt.slice(0, 11)+'...': txt;
        }else{
            return txt?.length > 50? txt.slice(0, 50)+'...': txt;
        }
    } 
    const descFormatter = (txt) => {
        if(window.innerWidth < 800){
            return txt?.length > 27? txt.slice(0, 27)+'...': txt;
        }else{
            return txt?.length > 50? txt.slice(0, 50)+'...': txt;
        }
    } 

  return (
    <div className='main-procard rounded-brand'>
        <div className="procard rounded-brand bg-brand-light0">
            <Link to={`/${props?.id}/${encodeURIComponent(props?.desc)}`} className='nav-link'>
                <div className="p-2">
                    <div className="procard-img text-center overflow-hidden p-2" style={{backgroundImage: `url('${props?.img}')`}}>
                        {/* <img src={props?.img} alt={props?.name} className="img-fluid rounded-brand" /> */}
                        <div className="water-wrap">
                            <img src="/assets/watermark.png" alt="watermark" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </Link>
            <div className="procard-details p-2">
                <Link to={`/${props?.id}/${encodeURIComponent(props?.desc)}`} className='nav-link'><p className="procard-name mb-0 body-font-extrabold text-uppercase text-center fs-sm text-brand-light-brown">{nameFormatter(props?.name)}</p></Link>
                <p className="procard-desc mb-0 body-font-bold text-center text-capitalize lh-sm fs-sm">{descFormatter(props?.desc)}</p>
                <div className="price-top-line rounded-pill mt-2"></div>
                <p className="text-center mb-0 fs-3 body-font-bold mt-2 mx-auto w-max-content"><span className="text-brand-light-brown body-font-extrabold">₹</span>{props?.discountprice}</p>
                <div className="addtocart-btn-wrap mt-2 w-100 text-center">
                    <button className="addtocart-btn btn w-max-content bg-brand-green mx-auto text-white rounded-pill body-font-semibold px-3">SHOP NOW</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Procard;