import React, { useEffect, useState } from 'react';
import './Dashstats.css';
import { Link } from 'react-router-dom';
import Tablecard from '../../Components/Tablecard/Tablecard';

function Dashstats() {

    const [allpro, setAllpro] = useState([]);
    const [mostviewed, setMostviewed] = useState([]);
    const [allmen, setAllmen] = useState([]);
    const [allwomen, setAllwomen] = useState([]);
    const [allcelebrity, setAllcelebrity] = useState([]);
    const [allswizz, setAllswizz] = useState([]);

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(()=> {
        fetch(`https://shahstore.co.in/api/`).then(res => res.json()).then(data => {
            console.log(data);
            if(data.success){
                setAllpro(data.pro);
                const mostviews = data.pro.sort((a, b) => b.views.length - a.views.length).slice(0, 10);
                setMostviewed(mostviews);
                const allmenpro = data.pro.filter(pro => pro.category.toLowerCase() === 'men watch');
                setAllmen(allmenpro);
                const allwomenpro = data.pro.filter(pro => pro.category.toLowerCase() === 'women watch');
                setAllwomen(allwomenpro);
                const allswizzpro = data.pro.filter(pro => pro.category.toLowerCase() === 'swizz eta watch');
                setAllswizz(allswizzpro);
                const allcelebritypro = data.pro.filter(pro => pro.category.toLowerCase() === 'celebrity watch');
                setAllcelebrity(allcelebritypro);
            }
        }).catch(err => console.log('error occured while getting all the product ', err));
    }, []);

    useEffect(() => {
        console.log(allmen.length, allwomen.length, allcelebrity.length, allswizz.length);
    }, [allmen, allwomen, allcelebrity, allswizz]);    

  return (
    <div className='main-dashstats'>
        <div className="container-fluid">
            <h2 className="body-font-bold fs-3 mt-3">Dashboard</h2>
            <div className="row p-0 m-0">
                <div className="col-md-3 col-6 p-2">
                    <Link to='products' className='nav-link'>
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-box"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allpro?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">PRODUCTS</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="/product/men-watch" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fi fi-rr-mars"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allmen?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">MEN</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="/product/women-watch" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fi fi-rr-venus"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allwomen?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">WOMEN</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-3 col-6 p-2">
                    <Link to="/product/celebrity-watch" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fi fi-rr-review"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allcelebrity?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">CELEBRITY</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-4 col-12 p-2">
                    <Link to="/product/swizz-eta-watch" className="nav-link">
                        <div className="bg-brand-light rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fi fi-rr-watch-smart"></i></p>
                                <div>
                                    <p className="mb-0 lh-sm fs-4 body-font-semibold">{allswizz?.length}</p>
                                    <p className="stat-txt mb-0 lh-sm body-font-bold text-muted">SWISS ETA</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="col-md-8 col-12 p-2">
                    <Link to="upload" className="nav-link">
                        <div className="bg-brand-light addpros rounded-brand py-3 px-3">
                            <div className="d-flex align-items-center justify-content-center">
                                <p className="fs-1 mb-0 lh-sm me-3"><i className="fas fa-plus"></i></p>
                                <div>
                                    <p className="stat-txt mb-0 lh-sm fs-5 body-font-bold">Add new Watches</p>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            <div className="top-pros mt-5">
                <h2 className="fs-5 body-font-bold pb-3">Top Ten Most Viewed</h2>
                <div className="row">   
                    {
                        mostviewed?.map((pro, ind) => (
                            <div key={ind} className="col-md-6 mb-4">
                                <Tablecard name={pro?.name} id={pro?.proid} price={pro?.price} disprice={pro?.discountprice} img={pro.images[0]} quantity={pro.views.length}/>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    </div>
  )
}

export default Dashstats;