import React, { useEffect } from 'react';
import './Profile.css';
import { useAuth } from '../../Context/Authcontext';
import { useNavigate, Link } from 'react-router-dom';

function Profile() {

    const { logout, userdata } = useAuth();
    const navigate = useNavigate();

    useEffect(()=> window.scrollTo(0, 0), []);

    useEffect(()=> console.log(userdata), [userdata]);

    const logoutfunc = () => {
        logout();
        navigate('/');
    }

  return (
    <div className='main-profile'>
        <div className="container py-5">
           <div className="profile-wrap mx-auto">
                <h2 className="fs-2 body-font-semibold mb-5">Your Account</h2>
                <div className="profile-content border rounded-brand mb-5 p-0">
                    <div className="border-bottom p-2 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="body-font-bold text-capitalize mb-0">Name</p>
                            <p className="body-font-medium text-capitalize mb-0">{userdata.name}</p>
                        </div>
                        <Link to="/editprofile" className="btn btn-outline-dark rounded-pill px-4"><i className="fas fa-pen me-2"></i>Edit</Link>
                    </div>
                    <div className="border-bottom p-2 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="body-font-bold text-capitalize mb-0">Number</p>
                            <p className="body-font-medium text-capitalize mb-0">{userdata.number}</p>
                        </div>
                        <Link to="/editprofile" className="btn btn-outline-dark rounded-pill px-4"><i className="fas fa-pen me-2"></i>Edit</Link>
                    </div>
                    <div className="border-bottom p-2 d-flex justify-content-between align-items-center">
                        <div>
                            <p className="body-font-bold text-capitalize mb-0">Email</p>
                            <p className="body-font-medium mb-0 text-break">{userdata.email}</p>
                        </div>
                        <Link to="/editprofile" className="btn btn-outline-dark rounded-pill px-4 text-nowrap"><i className="fas fa-pen me-2"></i>Edit</Link>
                    </div>
                    <div className="p-2 d-flex justify-content-between align-items-start">
                        <div className='me-3'>
                            <p className="body-font-bold text-capitalize mb-2">Address</p>
                            {
                                userdata?.address?.map((ad, ind) => (
                                    <p key={ind} className="body-font-medium text-capitalize mb-2">{ad}.</p>
                                ))
                            }
                        </div>
                        <Link to="/editprofile" className="btn btn-outline-dark rounded-pill px-4 text-nowrap"><i className="fas fa-pen me-2"></i>Edit</Link>
                    </div>
                </div>
                <div className='profile-content border rounded-brand mb-5 p-0'>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/' className="nav-link body-font-medium">Saved Products</Link>
                    </div>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/history' className="nav-link body-font-medium">Your Orders</Link>
                    </div>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/cart' className="nav-link body-font-medium">Cart</Link>
                    </div>
                    <div className="p-2 py-3">
                        <Link to='/editprofile' className="nav-link body-font-medium">Edit Profile</Link>
                    </div>
                </div>
                <div className='profile-content border rounded-brand mb-5 p-0'>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/terms-and-conditions' className="nav-link body-font-medium">Terms & Conditions</Link>
                    </div>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/privacy-policy' className="nav-link body-font-medium">Privacy Policy</Link>
                    </div>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/return-and-refund-policy' className="nav-link body-font-medium">Return & Refund Policy</Link>
                    </div>
                    <div className="p-2 py-3 border-bottom">
                        <Link to='/user-generated-content-policy' className="nav-link body-font-medium">User Generated Content Policy</Link>
                    </div>
                    <div className="p-2 py-3">
                        <Link to='/disclaimer' className="nav-link body-font-medium">Disclaimer</Link>
                    </div>
                </div>
                <button className="btn btn-outline-dark rounded-pill px-4 letter-spacing body-font-light" onClick={logoutfunc}>LOG OUT</button>
           </div>
        </div>
    </div>
  )
}

export default Profile