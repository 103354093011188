import React, { useState } from 'react';
import './Productcard.css';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Productcard(props) {

    const { isadmin } = useAuth();
    const navigate = useNavigate();
    const [ishover, setIshover] = useState(false);
    const [delcondis, setDelcondis] = useState("none");

    const mobNaviFunc = (link, e) =>{
        if(window.innerWidth < 700){
            navigate(link)
        }else{
            if(e.target.classList.contains('tag-wrap') || e.target.classList.contains('heart-n-share') || e.target.classList.contains('more-info-div')){
                navigate(link);
            }
        }
    }

    const textShorter = (txt) => {
        if(window.innerWidth > 800){
            if(txt){
                if(txt.length > 50){
                    const shortname = txt.slice(0, 50);
                    return shortname + '...'
                }else{
                    return txt
                }
            }
        }else{
            if(txt){
                if(txt.length > 0){
                    const shortname = txt.slice(0, 20);
                    return shortname + '...'
                }
            }
        }
    }

    const delFunc = () => {
        fetch(`https://shahstore.co.in/api/delpro/${props?.id}`).then(res => res.json()).then(data => {
            if(data.success){
                window.location.reload();
            }else{
                console.log(data);
            }
        })
    }

  return (
    <div className='main-product-card' onClick={(e)=> mobNaviFunc(`/${props?.id}/${encodeURIComponent(props?.name)}`, e)}>
        <div 
            className="product-card rounded-brand-lg overflow-hidden border cursor-pointer"  
            style={{backgroundImage: `url(${ishover? props?.hoverimg: props?.coverimg})`}} 
            onMouseEnter={()=> setIshover(true)} onMouseLeave={()=> setIshover(false)}
            >
            <div className="tag-wrap">
                {
                    props?.tag?.toLowerCase() === 'new' && (
                        <p className="product-tag mb-0 bg-brand-green rounded-pill w-max-content px-3 body-font-semibold">NEW</p>
                    )
                }
                {
                    props?.tag?.toLowerCase() === 'sale' && (
                        <p className="product-tag mb-0 bg-brand-red text-white rounded-pill w-max-content px-3 body-font-semibold">SALE</p>
                    )
                }
            </div>
            <div className="more-info-div d-flex flex-column justify-content-between">
                <div className="d-flex flex-column align-items-end heart-n-share">
                    <p className="hover-info-icon mb-0 me-2 fs-5 lh-sm"><i className="far fa-heart m-0 p-0 lh-sm"></i></p>
                    <p className="hover-info-icon mb-0 me-2 fs-6 lh-sm mt-2"><i className="fas fa-share-alt m-0 p-0 lh-sm"></i></p>
                </div>
            </div>
        </div>
        <Link to={`/${props?.id}/${encodeURIComponent(props?.name)}`} className="nav-link name-n-price mt-3">
            <p className="pro-name mb-1 body-font-semibold lh-sm"> {textShorter(props?.name)}</p>
            <div className="d-flex align-items-center">
                <p className="mb-0 body-font-medium body-font-semibold"> ₹{props.price}</p>
                {/* <p className="discount-tag mb-0 bg-brand-green rounded-pill px-2 ms-3">-{100 - Math.round(props.price/props.preprice*100)}%</p> */}
            </div>
        </Link>
        {
            isadmin && (
                <div className="d-flex justify-content-end align-items-center mt-1 extra-btns">
                    <Link to={`/dashboard/editproduct/${props?.id}`} className="mb-0 text-muted px-3 py-1 bg-brand-light rounded-pill cursor-pointer me-2"><i className="fas fa-pen"></i></Link>
                    <p className="mb-0 text-danger px-3 py-1 bg-brand-light rounded-pill cursor-pointer" onClick={() => setDelcondis('flex')}><i className="fas fa-trash-alt"></i></p>
                </div>
            )
        }
        {
            isadmin && (
                <div className="full-overlay" style={{display: delcondis}}>
                    <div className="del-conf bg-white rounded-brand p-3 py-4">
                        <h2 className="text-center body-font-semibold fs-4">Do you want to <span className="body-font-bold text-danger">Delete</span> {props?.id}</h2>
                        <div className="mt-4 row justify-content-between align-items-center">
                            <div className="col-6">
                                <button className="w-100 btn btn-dark px-3 py-2 letter-spacing body-font-light" onClick={()=> setDelcondis('none')}>NO</button>
                            </div>
                            <div className="col-6">
                                <button className="w-100 btn btn-outline-dark px-3 py-2 letter-spacing body-font-light" onClick={delFunc}>YES</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    </div>
  )
}

export default Productcard