import React, { useEffect, useState } from 'react';
import './Ordersuser.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';
import Tablecard from '../../Components/Tablecard/Tablecard';

function Ordersuser() {

    const {isloggedin, userdata} = useAuth();
    const [allorders, setAllorders] = useState([]);
    const [orderpros, setOrderpros] = useState([]);
    const [products, setProducts] = useState([]);

    useEffect(() => window.scrollTo(0, 0), []);

    useEffect(() => {
        if(isloggedin){
            if(userdata && userdata?.order){
                const orders = userdata?.order?.reverse()
                setAllorders(orders);
            }
        }
    }, [isloggedin, userdata]);

    useEffect(() => console.log(allorders, orderpros, products), [allorders, orderpros, products])

    useEffect(() => {
        if(allorders && allorders?.length > 0){
            const fetchFunc = async () => {
                const allOrderpros = await Promise.all(allorders?.map(id => fetch(`https://shahstore.co.in/api/getorder/${id}`).then(res => res.json()).then(data => data.pro))).catch(err => console.log('error occured while getting all the orders ', err));
                const filteredData = allOrderpros.filter(pro => pro.status !== 'delivered');
                setOrderpros(filteredData);
            }
            fetchFunc();
        }
    }, [allorders])

    useEffect(() => {
        const fetchProducts = async () => {
            if (orderpros && orderpros.length > 0) {
                const allProducts = await Promise.all(orderpros.map(async (ord) => {
                    const products = await Promise.all(
                        ord.products.map(pro => 
                            fetch(`https://shahstore.co.in/api/pro/${pro.proid}`).then(res => res.json()).then(data => data.pro)
                        )
                    );
                    return products;
                }));
                console.log(allProducts);
                setProducts(allProducts);
            }
        };
    
        fetchProducts();
    }, [orderpros]);

    useEffect(() => console.log(orderpros), [orderpros])

    const splitFunc = (str)=> {
        const imgFst = str?.split(', ');
        return imgFst;
    }

    const estimateTimeFunc = (date) => {
        console.log(date);
        const currentDate = new Date(date);
        const newDate = currentDate;
        newDate.setDate(newDate.getDate() + 3);
        const estimateDate = `${newDate.getDate()} ${newDate.toLocaleDateString('en-US', {month: 'long'})}`;
        newDate.setDate(newDate.getDate() + 6);
        const lastestimateDate = `${newDate.getDate()} ${newDate.toLocaleDateString('en-US', {month: 'long'})}`;
        console.log(estimateDate, lastestimateDate);
        return {start: estimateDate, end: lastestimateDate};
    };

  return (
    <div className='main-orders'>
        <div className="container-fluid my-4">
            <h2 className="fs-2 body-font-bold mb-4">Your Orders</h2>
            <div className="m-0 p-0 row flex-column">
                {
                    products?.map((prolg, ind) => (
                        <div key={ind} className="order-card rounded-brand-lg mb-4 col-md-6">
                            <p className="mb-2 text-muted">Order ID: <span className="text-dark body-font-medium">{allorders[ind]}</span></p>
                            {
                                prolg.map((pro, index) => (
                                    <div key={index} className="p-0 m-0 mb-2">
                                        <Tablecard name={pro?.name} id={pro?.proid} price={pro?.price} disprice={pro?.discountprice} img={splitFunc(pro?.colornimage[0].url)[0]} quantity={orderpros[ind]?.products[index]?.quantity}/>
                                    </div>
                                ))
                            }
                            <div className="d-flex justify-content-between">
                                <p className="mb-0 fs-sm body-font-light text-success text-capitalize">Order {orderpros[ind]?.status? orderpros[ind]?.status: 'Pending'} <br /> <span className="text-muted">{estimateTimeFunc(orderpros[ind]?.orderdate).start} - {estimateTimeFunc(orderpros[ind]?.orderdate).end}</span></p>
                                <a href={`https://wa.me/+919082874084?text=I want to cancel my order. This is my order id ${allorders[ind]}`} target='_blank' rel="noreferrer" className="nav-link mb-0 fs-sm body-font-light text-danger d-block">Cancel</a>
                            </div>
                        </div>
                    ))
                }
            </div>

            {
                userdata?.order?.length < 1 && (
                    <div className="text-center mt-5 pt-5">
                        <p className="mb-0 body-font-bold fs-5">No products in cart</p>
                        <Link to="/product" className="btn btn-outline-dark px-3 py-2 body-font-light letter-spacing mt-3">EXPLORE</Link>
                    </div>
                )
            }
        </div>
    </div>
  )
}

export default Ordersuser