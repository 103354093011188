import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home/Home';
import Listing from './Pages/Listing/Listing';
import Detail from './Pages/Detail/Detail';
import Navbar from './Components/Navbar/Navbar';
import Dashboard from './Pages/Dashboard/Dashboard';
import TermsNconditions from './Pages/TermsNconditions/TermsNconditions';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy';
import Returnpolicy from './Pages/Returnpolicy/Returnpolicy';
import Disclaimer from './Pages/Disclaimer/Disclaimer';
import Userpolicy from './Pages/Userpolicy/Userpolicy';
import Footer from './Components/Footer/Footer';
import Signup from './Pages/Signup/Signup';
import Login from './Pages/Login/Login';
import { AuthPovider } from './Context/Authcontext';
import Profile from './Pages/Profile/Profile';
import History from './Pages/History/History';
import Editprofile from './Pages/Editprofile/Editprofile';
import Cart from './Pages/Cart/Cart';
import Adminpanel from './Pages/Adminpanel/Adminpanel';
import Dashstats from './Pages/Dashstats/Dashstats';
import Products from './Pages/Products/Products';
import Orders from './Pages/Orders/Orders';
import Alladmins from './Pages/Alladmins/Alladmins';
import Editproduct from './Pages/Editproduct/Editproduct';
import Confirm from './Pages/Confirm/Confirm';
import Leads from './Pages/Leads/Leads';
import Ordersuser from './Pages/Ordersuser/Ordersuser';
import Explore from './Pages/Explore/Explore';

function App() {
  return (
    <AuthPovider>
      <Router>
        <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/home' element={<Home/>}/>
          <Route path='/explore' element={<Explore/>}/>
          <Route path='/product' element={<Listing/>}/>
          <Route path='/product/:cate' element={<Listing/>}/>
          <Route path='/product/:cate/:brand' element={<Listing/>}/>
          <Route path='/terms-and-conditions' element={<TermsNconditions/>}/>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
          <Route path='/return-and-refund-policy' element={<Returnpolicy/>}/>
          <Route path='/user-generated-content-policy' element={<Userpolicy/>}/>
          <Route path='/disclaimer' element={<Disclaimer/>}/>
          <Route path='/signup' element={<Signup/>}/>
          <Route path='/login' element={<Login/>}/>
          <Route path='/profile' element={<Profile/>}/>
          <Route path='/orders' element={<Ordersuser/>}/>
          <Route path='/history' element={<History/>}/>
          <Route path='/cart' element={<Cart/>}/>
          <Route path='/confirmation' element={<Confirm/>}/>
          <Route path='/editprofile' element={<Editprofile/>}/>
          <Route path='/:id/:name' element={<Detail/>}/>
          <Route path='/dashboard' element={<Adminpanel/>}>
            <Route path='' element={<Dashstats/>}/>
            <Route path='upload' element={<Dashboard/>}/>
            <Route path='products' element={<Products/>}/>
            <Route path='editproduct/:proid' element={<Editproduct/>}/>
            <Route path='leads' element={<Orders/>}/>
            <Route path='orders' element={<Leads/>}/>
            <Route path='admins' element={<Alladmins/>}/>
          </Route>
        </Routes>
        <Footer/>
      </Router>
    </AuthPovider>
  );
}

export default App;
