import React from 'react';
import './Tablecard.css';
import { Link } from 'react-router-dom';
import { useAuth } from '../../Context/Authcontext';

function Tablecard(props) {

    const {userdata, login, isloggedin} = useAuth();
    // const [dis, setDis] = useState('block');

    const removeFunc = () => {
        if(isloggedin){
            fetch(`https://shahstore.co.in/api/removecart/${userdata._id}/${props.id}`).then(res => res.json()).then(data => {
                if(data.success){
                    login(data.pro);
                    console.log(data.pro);
                    if(data.pro.cart.length < 1){
                        window.location.reload();
                    }
                    // setDis('none');
                }else{
                    console.log(data);
                }
            }).catch(err => console.log('error occured while removing from cart ', err));
        }else{
            const localcart = localStorage.getItem("cart");
            if(localcart){
                const parsedcart = JSON.parse(localcart);
                let proind = -1;
                parsedcart?.forEach((pro, ind) => {
                    if(props?.id === pro.id){
                        proind = ind;
                        console.log(ind);
                    }
                });
                if(proind !== -1){
                    console.log(parsedcart);
                    parsedcart?.splice(proind, 1);
                    localStorage.setItem("cart", JSON.stringify(parsedcart));
                    window.location.reload();
                }else{
                    console.log('product not found in cart')
                }
            }
        }
    }

  return (
    <div className='main-table-card rounded-brand-lg overflow-hidden border'>
        <div className='p-0 m-0'>
            <div className="table-card-wrap row align-items-center p-0 m-0">
                <div className="col-md-3 col-4 p-0">
                    <Link to={`/${props?.id}/${encodeURIComponent(props?.name)}`} className='nav-link'>
                        <img src={props?.img} alt={props?.name} className="img-fluid rounded-brand" />
                    </Link>
                </div>
                <div className="col-md-9 col-8 py-2">
                    <Link to={`/${props?.id}/${encodeURIComponent(props?.name)}`} className="nav-link mb-0 body-font-bold text-capitalize">{props.name.length > 50? props.name.slice(0, 20) + '...': props.name }</Link>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0 body-font-bold">₹{props?.disprice} <span className="fs-sm text-muted body-font-light text-decoration-line-through ms-2">₹{props?.price}</span></p>
                        <div className="col-md-3 col-6 justify-content-between align-items-center border rounded-brand-sm overflow-hidden" style={{display: window.location.pathname === '/cart'? 'flex': 'none'}}>
                            <button className='btn bg-white' onClick={()=> props.changeFunc(props.quantity > 1? props.quantity - 1: 1, props.ind)}>-</button>
                            <p className="mb-0 px-1">{props.quantity}</p>
                            <button className='btn bg-white' onClick={()=> props.changeFunc(props.quantity < 10? props.quantity + 1: 10, props.ind)}>+</button>
                        </div>
                        <div className="col-md-2 col-4 rounded-brand-sm" style={{display: window.location.pathname === '/orders'? 'block': 'none'}}>
                            <p className="mb-0 text-center">QTY: {props.quantity}</p>
                        </div>
                        <div className="col-md-2 col-4 rounded-brand-sm" style={{display: window.location.pathname === '/history'? 'block': 'none'}}>
                            <p className="mb-0 text-center">QTY: {props.quantity}</p>
                        </div>
                        <div className="col-md-2 col-4 border py-1 rounded-brand-sm" style={{display: window.location.pathname === '/dashboard'? 'block': 'none'}}>
                            <p className="mb-0 px-1 text-center">{props.quantity}</p>
                        </div>
                    </div>
                    <div className="align-items-center justify-content-between mt-2" style={{display: window.location.pathname === '/cart'? 'flex': 'none'}}>
                        <p className="mt-2 text-muted fs-sm body-font-light mb-0">You save: <span className="text-danger body-font-bold">₹{props?.price - props?.disprice}</span></p>
                        <p className="mb-0 text-danger body-font-semibold fs-sm cursor-pointer" onClick={removeFunc}>Remove</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Tablecard;

// onClick={()=> setQuantity(props.quantity > 1? props.quantity - 1: 1)}
// onClick={()=> setQuantity(props.quantity < 10? props.quantity + 1: 10)}