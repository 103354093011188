import React, { useEffect } from 'react';
import './Disclaimer.css';

function Disclaimer() {

    useEffect(()=> window.scrollTo(0, 0), []);

  return (
    <div className='container my-5'>
        <h2 className="body-font-bold mb-4 fs-3">Disclaimer</h2>
        <p className="mb-4">
            Welcome to Shah. The information provided on our website, <a href="Shah.netlify.app" target='_blank' className="nav-link d-inline text-primary">www.Shah.com</a>, is for general informational purposes only. By accessing or using our website, you agree to this Disclaimer. If you do not agree, please do not use our website.
        </p>
        <div className="content">
            <ol className='m-0'>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Product Descriptions and Images</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Accuracy:</span> We strive to provide accurate descriptions, images, and prices of our products. However, due to variations in display settings and menufacturing processes, the color, design, and appearance of the handbags on your screen may differ slightly from the actual product.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Errors and Omissions:</span> While we make every effort to ensure the accuracy of the information on our website, we are not responsible for any errors or omissions. We reserve the right to correct any inaccuracies or to update information at any time without prior notice, including after you have placed an order.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">No Warranties</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>General Use:</span> The website and all content, materials, and products available on it are provided "as is" and "as available" without any warranties of any kind, either express or implied. This includes, but is not limited to, implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Availability:</span> We do not warrant that the website will be available at all times or that it will be free from errors, viruses, or other harmful components. You use the website at your own risk.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Limitation of Liability</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>General Liability:</span> To the fullest extent permitted by law, Shah, its directors, employees, or affiliates shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising from your use of the website, products purchased from the website, or your reliance on any information provided on the website.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>No Responsibility:</span> We are not responsible for any losses, damages, or injury resulting from hacking, tampering, or other unauthorized access to or use of our services or your account.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Third-Party Links</p>
                    <ol className='m-0' type='I'>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>External Websites:</span> Our website may contain links to third-party websites that are not operated by us. These links are provided for your convenience, and we do not endorse or assume any responsibility for the content, privacy policies, or practices of these third-party websites.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>User Responsibility:</span> If you access a third-party website through a link on our site, you do so at your own risk. We encourage you to review the terms and conditions and privacy policies of any third-party sites you visit.
                            </p>
                        </li>
                        <li>
                            <p className="text-muted">
                                <span className='body-font-medium text-black'>Data Deletion:</span> You have the right to request the deletion of your personal information. To do so, please contact us at email@gmail.com. We will take reasonable steps to delete your data, except where we are required to retain it for legal or business purposes.
                            </p>
                        </li>
                    </ol>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Testimonials and Reviews</p>
                    <p className="text-muted">
                    Any testimonials, reviews, or endorsements provided by customers on our website are their opinions and experiences. They are provided for informational purposes only and do not constitute a guarantee, warranty, or prediction of what your experience with our products will be..
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Indemnification</p>
                    <p className="text-muted">
                    User Agreement: You agree to indemnify, defend, and hold harmless Shah, its affiliates, and its respective officers, directors, employees, and agents from any claims, damages, losses, liabilities, costs, and expenses arising from your use of the website, your violation of this Disclaimer, or your infringement of any intellectual property or other right of any person or entity.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Changes to This Disclaimer</p>
                    <p className="text-muted">
                    We reserve the right to update or modify this Disclaimer at any time without prior notice. Any changes will be posted on this page with an updated "Last Updated" date. Your continued use of the website after any changes indicates your acceptance of the new terms.
                    </p>
                </li>
                <li className='mb-4'>
                    <p className="fs-5 body-font-medium">Contact Information</p>
                    <p className="text-muted">
                        If you have any questions or concerns regarding these Terms and Conditions, please contact us at:
                    </p>
                    <p className="mb-0">Shah Customer Service</p>
                    <p className="mb-0">Email: <a href="mailto:email@gmail.com" className="nav-link d-inline text-primary">email@gmail.com</a></p>
                    <p className="mb-0">Phone no.: <a href="tel:+919082874084" className="nav-link d-inline text-primary">+91 90828 74084</a></p>
                </li>
            </ol>
        </div>
    </div>
  )
}

export default Disclaimer;