import React, { useEffect, useState } from 'react';
import './Explore.css';
import Procard from '../../Components/Procard/Procard';
import stringSimilarity from 'string-similarity';

const Explore = () => {

    const [allpros, setAllpros] = useState([]);
    const [searchval, setSearchval] = useState('');
    const [searchpros, setSearchpros] = useState([]);
    const [issearched, setIssearched] = useState(false);
    
    useEffect(() => {
        const fetchFunc = async () => {
            const response = await fetch(`https://shahstore.co.in/api/`);
            const data = await response.json();
            console.log(data);
            if(data.success){
                setAllpros(data.pro.reverse());
            }
        };
        fetchFunc();
    }, []);

    const ignoreTerms = ['watch', 'watches', 'clock', 'clocks'];

    const searchFunc = () => {
        let formattedSearch = searchval.trim().toLowerCase();
        if(formattedSearch.split(" ").length > 0){
            const newword = formattedSearch.split(" ").filter(e => !ignoreTerms.includes(e));
            formattedSearch = newword.join(' ');
        }
        console.log(formattedSearch);
        const filterPros = allpros.filter(pro => {
            return Object.values(pro).some(val => {
                if (Array.isArray(val)) {
                    return val.some(item => {
                        const itemStr = typeof item === 'object' && item !== null ? 
                            JSON.stringify(Object.values(item)).toLowerCase() : 
                            String(item).toLowerCase();
                        return stringSimilarity.compareTwoStrings(itemStr, formattedSearch) > 0.4;
                    });
                }
                const valStr = String(val).toLowerCase();
                return stringSimilarity.compareTwoStrings(valStr, formattedSearch) > 0.4 || valStr.includes(formattedSearch);
            })
        });
        console.log(filterPros);
        setSearchpros(filterPros);
        setIssearched(true);
    }

    useEffect(() => {
        const localsearch = localStorage.getItem("localsearch");
        if(localsearch){
            setSearchval(localsearch);
            setTimeout(() => {
                const formattedSearch = searchval.trim().toLowerCase();
                const filterPros = allpros.filter(pro => {
                    return Object.values(pro).some(val => {
                        if (Array.isArray(val)) {
                            return val.some(item => {
                                const itemStr = typeof item === 'object' && item !== null ? 
                                    JSON.stringify(Object.values(item)).toLowerCase() : 
                                    String(item).toLowerCase();
                                return stringSimilarity.compareTwoStrings(itemStr, formattedSearch) > 0.4;
                            });
                        }
                        const valStr = String(val).toLowerCase();
                        return stringSimilarity.compareTwoStrings(valStr, formattedSearch) > 0.4 || valStr.includes(formattedSearch);
                    })
                });
                console.log(filterPros);
                setSearchpros(filterPros);
                setIssearched(true);
                localStorage.removeItem("localsearch");
            }, 1000);
        }
    }, [allpros, searchval]);

  return (
    <div className='main-explore'>
        <div className="container py-5 pt-4">
            <div className="search-box-wrap rounded-pill border border-brand-green">
                <div className="d-flex align-items-center justify-content-between bg-white w-100 rounded-pill p-3 py-2">
                    <input type="text" className="form-control border-0 shadow-none outline-none p-0" onChange={(e)=> setSearchval(e.target.value)} value={searchval} onKeyDown={(e) => e.key === 'Enter' && searchFunc()} placeholder="Search..."/>
                    <p className="mb-0 text-brand-green cursor-pointer ms-3" onClick={searchFunc}><i className="fas fa-search fs-5" aria-hidden="true"></i></p>
                    {/* <p className="mb-0 text-brand-green"><i className="fi fi-rr-settings-sliders ms-3 fs-5"></i></p> */}
                </div>
            </div>

            {
                issearched && (
                    <div className="row mt-5">
                        <h2 className="mb-2 head-font-bold fs-4"><span className="head-font-extrabold text-brand-light-brown">Searched</span> Products</h2>
                        {
                            searchpros?.length > 0? searchpros?.map((pro, ind) => (
                                <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                                    <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} discountprice={pro.discountprice} desc={pro.name} price={pro.price} category={pro.category} star={pro.stars} reviewcount={pro.noreviews} />
                                </div>
                            )):(
                                <p className="mt-3 mb-5 text-muted text-center body-font-bold fs-5">No Product Found!</p>
                            )
                        }
                    </div>
                )
            }

            <div className="row mt-5">
                <h2 className="mb-2 head-font-bold fs-4"><span className="head-font-extrabold text-brand-light-brown">Latest</span> Products</h2>
                {
                    allpros?.slice(0, 8).map((pro, ind) => (
                        <div key={ind} className="col-md-3 col-6 p-2 mb-3">
                            <Procard id={pro.proid} img={pro.images[0]} tag={pro.status} name={pro.brand} discountprice={pro.discountprice} desc={pro.name} price={pro.price} category={pro.category} star={pro.stars} reviewcount={pro.noreviews} />
                        </div>
                    ))
                }
            </div>

        </div>
    </div>
  )
}

export default Explore;