import React, { useEffect, useState } from 'react';
import "./Cart.css";
import { useAuth } from '../../Context/Authcontext';
import Tablecard from '../../Components/Tablecard/Tablecard';
import { Link, useNavigate } from 'react-router-dom';

function Cart() {

    const navigate = useNavigate();
    const {userdata, isloggedin, login} = useAuth();
    const [cartpros, setCartpros] = useState([]);
    const [quan, setQuan] = useState([]);
    const [totalprice, setTotalprice] = useState(0);
    const [discountprice, setDiscountprice] = useState(0);
    const [total, setTotal] = useState(0);
    const [adpopind, setAdpopind] = useState(0);
    const [roomno, setRoomno] = useState("");
    const [buildingname, setBuildingname] = useState("");
    const [landmark, setLandmark] = useState("");
    const [city, setCity] = useState("");
    const [pincode, setPincode] = useState("");
    const [state, setState] = useState("");
    const [address, setAddress] = useState([]);
    const [upcart, setUpcart] = useState([]);
    const [selectedadd, setSelectedadd] = useState('');
    const [checkout, setCheckout] = useState('none');
    const [signupform, setSignupform] = useState({
        number: '',
        address: [],
        password: ''
    })
    // const [orderdat, setOrderdat] = useState([]);

    useEffect(()=> window.scrollTo(0, 0), []);

    const handleAdd = (e) => {
        setSelectedadd(e.target.value);
    }

    useEffect(() => console.log(selectedadd), [selectedadd]);

    useEffect(()=> {
        if(!isloggedin){
            const localuser = localStorage.getItem("usernum");
            if(localuser){
                const localnum = JSON.parse(localuser);
                setSignupform(x => ({...x, number: localnum}));
            }
        }
    }, [isloggedin])

    useEffect(() => {
        if(isloggedin){
            const cartFunc = async () => {
                if(userdata?.cart?.length > 0){
                    const cartarr = await Promise.all(userdata?.cart?.map(pro => fetch(`https://shahstore.co.in/api/pro/${pro.id}`).then(res => res.json()).then(data => data.pro))).catch(err => console.log('error occured while getting all the pro of cart ', err));
                    setCartpros(cartarr);
                };
            };
            cartFunc();
        }else{
            const localcart = localStorage.getItem("cart");
            if(localcart){
                const parsedcart = JSON.parse(localcart);
                const cartFunc = async () => {
                    if(parsedcart?.length > 0){
                        const cartarr = await Promise.all(parsedcart?.map(pro => fetch(`https://shahstore.co.in/api/pro/${pro.id}`).then(res => res.json()).then(data => data.pro))).catch(err => console.log('error occured while getting all the pro of cart ', err));
                        setCartpros(cartarr);
                    };
                };
                cartFunc();
            }
        }
    }, [userdata?.cart, isloggedin]);

    useEffect(() => console.log(cartpros, userdata?.cart), [cartpros, userdata?.cart]);

    useEffect(() => {
        if(userdata?.cart && quan){
            const newarr = [];
            userdata?.cart?.forEach((car, ind) => {
                newarr.push({proid: car.id, quantity: quan[ind]? quan[ind]: 1, proname: car.proname});
            });
            setUpcart(newarr);
        }else{
            if(localStorage.getItem('cart') && quan){
                const localcart = JSON.parse(localStorage.getItem('cart'));
                const cartarr = []
                console.log(localcart);
                localcart.forEach((cart, ind) => {
                    console.log(cart, ind)
                    cartarr.push({proid: cart.id, quantity: quan[ind]? quan[ind]: 1, proname: cart.proname});
                    cart.quantity = quan[ind];
                })
                localStorage.setItem('cart', JSON.stringify(localcart));
                setUpcart(cartarr);
            }
        }
    }, [quan, userdata?.cart]);

    useEffect(()=> console.log(upcart), [upcart]);

    const splitFunc = (str)=> {
        const imgFst = str?.split(', ');
        return imgFst;
    }

    useEffect(() => {
        if(isloggedin){
            if(userdata && userdata?.cart?.length > 0){
                const temparr = [];
                userdata?.cart.forEach(pro => {
                    temparr.push(pro.quantity);
                });
                setQuan(temparr);
            }
        }else{
            const localcart = localStorage.getItem('cart');
            console.log(isloggedin);
            if(localcart){
                const parsedcart = JSON.parse(localcart);
                const temparr = [];
                parsedcart?.forEach(pro => {
                    temparr.push(pro.quantity);
                    console.log('this is pro quantity', pro.quantity);
                });
                setQuan(temparr);
            }
        }
    }, [userdata, isloggedin])

    useEffect(() => {
        const localcart = localStorage.getItem('cart');
        if(localcart){
            const parsedcart = JSON.parse(localcart);
            const temparr = [];
            parsedcart?.forEach(pro => {
                temparr.push(pro.quantity? pro.quantity: 1);
            });
            setQuan(temparr);
        }
    }, [])

    const updateQuanFunc = (num, ind) => {
        const tempquant = [...quan];
        tempquant[ind] = num;
        setQuan(tempquant);
    }

    useEffect(() => console.log('this is quan arr', quan), [quan]);

    useEffect(() => {
        if(cartpros?.length > 0){
            let tempprice = 0;
            let discount = 0;
            cartpros?.forEach((pro, ind) => {
                tempprice += pro.price * quan[ind];
                discount += (pro.price * quan[ind]) - (pro.discountprice * quan[ind]);
            });
            setTotalprice(tempprice);
            setDiscountprice(discount);
        }
    }, [cartpros, quan]);

    useEffect(() => setTotal(totalprice - discountprice), [totalprice, discountprice]);

    // const checkoutFunc = () => {
    //     if(isloggedin){
    //         const orderarr = [];
    //         if(userdata){
    //                 userdata?.cart?.forEach((pro, ind) => {
    //                 orderarr.push({proid: pro.id, proname: cartpros[ind].name, quantity: quan[ind]});
    //                 // console.log('this is awesome stuff', {id: pro.id, quantity: quan[ind]})
    //             });
    //             // console.log(orderarr);
    //         }else{
    //             console.log('this is stupid');
    //         }

    //         fetch(`https://shahstore.co.in/api/order/${userdata._id}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({products: orderarr})
    //         }).then(res => res.json()).then(data => {
    //             if(data.success){
    //                 console.log(data);
    //                 login(data.pro);
    //             }else{
    //                 console.log('success was not true ', data);
    //             }
    //         }).catch(err => console.log('error occured while placing order ', err));
    //     }
    // }

    useEffect(()=> {
        setSignupform(x => ({...x, address: [`${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`]}));
    }, [roomno, buildingname, landmark, city, pincode, state]);

    useEffect(() => {
        if(isloggedin){
            setAddress([...userdata?.address,`${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`]);
        }
    }, [roomno, buildingname, landmark, city, pincode, state, isloggedin, userdata]);

    useEffect(() => console.log(address), [address])

    useEffect(() => console.log(signupform), [signupform]);

    const confirmOrder = () => {
        if(isloggedin){
            if(roomno !== ''){
                fetch(`https://shahstore.co.in/api/edituser/${userdata?._id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({address: [...userdata?.address, `${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`]})
                }).then(res => res.json()).then(data => {
                    if(data.success){
                        console.log(data);
                        login(data.pro);
                        fetch(`https://shahstore.co.in/api/order/${userdata?._id}`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({products: upcart, address: `${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`})
                        }).then(res => res.json()).then(data => {
                            if(data.success){
                                login(data.pro);
                                console.log('now naviating')
                                navigate('/confirmation');
                            }else{
                                console.log('success not true', data);
                            }
                        }).catch(err => console.log('error occured while placing the order ', err));
                    }else{
                        console.log('success false');
                    }
                }).catch(err => console.log('error occured while updating the profile ', err));
            }else{
                fetch(`https://shahstore.co.in/api/order/${userdata?._id}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({products: upcart, address: selectedadd})
                }).then(res => res.json()).then(data => {
                    if(data.success){
                        login(data.pro);
                        console.log(data);
                        navigate('/confirmation')
                    }else{
                        console.log('success not true', data);
                    }
                }).catch(err => console.log('error occured while placing the order ', err));
            }
        }else{
            fetch(`https://shahstore.co.in/api/signup`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(signupform)
            }).then(res => res.json()).then(data => {
                console.log(data);
                if(data.success){
                    login(data.pro);
                    fetch(`https://shahstore.co.in/api/order/${data?.pro?._id}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({products: upcart, address: `${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`})
                    }).then(res => res.json()).then(data => {
                        if(data.success){
                            localStorage.clear();
                            login(data.pro);
                            console.log('now naviating')
                            navigate('/confirmation');
                        }else{
                            console.log('success not true', data);
                        }
                    }).catch(err => console.log('error occured while placing the order ', err));
                }else{
                    console.log('success is not true');
                }
            }).catch(err => console.log('error occured while siningin ', err))
        }
    }

  return (
    <div className='main-cart'>
        <div className="container-fluid my-5">
            <h2 className="fs-2 body-font-bold mb-4">Cart</h2>
            <div className="row p-0 m-0">
                <div className="row p-0 m-0 col-md-8">
                    {
                        cartpros?.map((pro, ind) => (
                            <div key={ind} className="col-md-12 mb-4 p-0">
                                <Tablecard name={pro?.name} id={pro?.proid} price={pro?.price} disprice={pro?.discountprice} img={splitFunc(pro?.colornimage[0].url)[0]} quantity={quan[ind]} ind={ind} changeFunc={updateQuanFunc} arr={cartpros}/>
                            </div>
                        ))
                    }
                </div>
                <div className="col-md-4 p-2">

                    {
                        cartpros?.length > 0 && (
                            <div className="checkout-div py-3 rounded-brand border px-0 overflow-hidden">
                                <div className="row mb-3 px-3">
                                    <div className="col-8">
                                        <p className="mb-0 body-font-semibold fs-5">Price Details</p>
                                    </div>
                                </div>
                                <div className="row mb-2 px-3">
                                    <div className="col-8">
                                        <p className="mb-0">Total MRP</p>
                                        <p className="mb-0 lh-sm text-muted fs-sm body-font-light">Included of all taxes</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="mb-0 text-end body-font-medium">₹{totalprice}</p>
                                    </div>
                                </div>
                                <div className="row mb-2 px-3">
                                    <div className="col-8">
                                        <p className="mb-0">Subtotal</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="mb-0 text-end body-font-medium">₹{totalprice}</p>
                                    </div>
                                </div>
                                <div className="row mb-2 px-3">
                                    <div className="col-8">
                                        <p className="mb-0">Discount</p>
                                    </div>
                                    <div className="col-4">
                                            <p className="mb-0 text-end body-font-medium text-success">-₹{discountprice}</p>
                                    </div>
                                </div>
                                <div className="row px-3 mb-2 border-top border-bottom py-2 mt-4">
                                    <div className="col-8">
                                        <p className="mb-0 body-font-medium fs-5">Total</p>
                                    </div>
                                    <div className="col-4">
                                        <p className="mb-0 text-end body-font-medium fs-5">₹{total}</p>
                                    </div>
                                </div>
                                <p className="text-center text-success body-font-bold mt-3">You save ₹{discountprice} on this order.</p>
                                <div className="text-center w-100">
                                    <button className="btn btn-brand-light-brown w-75 py-2 rounded-pill letter-spacing body-font-light" onClick={()=> setCheckout('flex')}>CHECKOUT</button>
                                </div>
                            </div>
                        )
                    }
                </div>
                    {
                        userdata?.cart?.length < 1 && (
                            <div className="text-center mt-5 pt-5">
                                <p className="mb-0 body-font-bold fs-5">No products in cart</p>
                                <Link to="/product" className="btn btn-outline-dark px-3 py-2 body-font-light letter-spacing mt-3">EXPLORE</Link>
                            </div>
                        )
                    }
            </div>
        </div>

        <div className="hover-checkout-wrap px-0 pt-2">
            <p className="mb-0 text-center text-success body-font-bold bg-success-subtle py-1">You save ₹{discountprice} on this order.</p>
            <button className="btn btn-brand-light-brown w-100 py-3 rounded-0 letter-spacing" onClick={()=> setCheckout('flex')}>CHECKOUT</button>
        </div>

        <div className="full-overlay checkout-wrap" style={{display: checkout}}>
            <div className="checkout-popup bg-white rounded-brand py-4 p-2" style={{display: adpopind === 0? 'block': 'none'}}>
                <h2 className="fs 3 body-font-bold text-center">Select Delivery Address</h2>
                <div className="main-checkout-pop mt-4">
                    {
                        isloggedin ? (
                            <div className="addresses-wrap">
                                {
                                    userdata?.address?.map((ad, ind) => (
                                        <div key={ind} className="d-flex align-items-center">
                                            <div className="radio-btn-wrap">
                                                <input type="radio" name='address' value={ad} id={`address${ind}`} onChange={handleAdd} className='me-1'/>
                                            </div>
                                            <div className="address-wrap p-2 rounded-brand-sm">
                                                <label htmlFor={`address${ind}`}><p className="mb-0 text-muted text-capitalize">{ad}</p></label>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        ):(
                            <div className="address-wrap mb-3 p-2 border border-brand-light-brown rounded-brand-sm py-5">
                                <p className="mb-0 body-font-bold text-center text-brand-light-brown">No address found!</p>
                                <p className="mb-0 text-muted text-center">Add new address to get your products</p>
                            </div>
                        )
                    }

                    
                    {
                        isloggedin ? (
                            <div className='addbtnwrap'>
                                <button className="btn border border-brand-light-brown text-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2 mt-4 mb-2" onClick={() => setAdpopind(1)}>+ ADD NEW ADDRESS</button>
                                <button className="btn btn-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2" onClick={() => setAdpopind(3)}>PROCEED</button>
                            </div>
                        ): (
                            <button className="btn btn-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2" onClick={() => setAdpopind(1)}>+ ADD NEW ADDRESS</button>
                        )
                    }
                </div>
            </div>
            <div className="checkout-popup bg-white rounded-brand py-4 p-2" style={{display: adpopind === 1? 'block': 'none'}}>
                <h2 className="fs-3 body-font-bold text-center">Delivery Address</h2>
                <div className="main-checkout-pop mt-4">
                    <div className="address-form">
                        <div className="row p-0 m-0 mb-3">
                            <div className="col-4 p-0 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='Room no.' value={roomno} onChange={(e)=> setRoomno(e.target.value)} />
                            </div>
                            <div className="col-8 p-0 ps-2 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='Building name' value={buildingname} onChange={(e)=> setBuildingname(e.target.value)} />
                            </div>
                        </div>
                        <div className="row p-0 m-0 mb-3">
                            <div className="col-12 p-0 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='Landmark' value={landmark} onChange={(e)=> setLandmark(e.target.value)} />
                            </div>
                        </div>
                        <div className="row p-0 m-0 mb-3">
                            <div className="col-8 p-0 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='City' value={city} onChange={(e)=> setCity(e.target.value)} />
                            </div>
                            <div className="col-4 p-0 ps-2 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='Pincode' value={pincode} onChange={(e)=> setPincode(e.target.value)} />
                            </div>
                        </div>
                        <div className="row p-0 m-0 mb-3">
                            <div className="col-12 p-0 m-0">
                                <input type="text" className='form-control shadow-none border border-dark outline-none rounded-brand-sm text-capitalize' placeholder='State' value={state} onChange={(e)=> setState(e.target.value)} />
                            </div>
                        </div>
                    </div>

                    {
                        isloggedin ? (
                            <button className="btn btn-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2 mt-4" onClick={()=> setAdpopind(3)}>PLACE ORDER</button>
                        ):(
                            <button className="btn btn-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2 mt-4" onClick={()=> setAdpopind(2)}>PLACE ORDER</button>
                        )
                    }
                </div>
            </div>
            {
                !isloggedin && (
                    <div className="checkout-popup bg-white rounded-brand py-4 p-2" style={{display: adpopind === 2? 'block': 'none'}}>
                        <h2 className="fs-3 body-font-bold text-center mb-0">Create Password</h2>
                        <p className="mb-0 text-muted fs-sm text-center">For your Account.</p>
                        <div className="mt-4 mb-5 pb-1">
                            <input type="password" className="form-control border border-dark rounded-brand-sm shadow-none outline-none" placeholder='Create password' value={signupform?.password} onChange={(e)=> setSignupform(x => ({...x, password: e.target.value}))} />
                        </div>
                        <button className="btn btn-brand-light-brown w-100 rounded-brand-sm letter-spacing py-2 mt-5" onClick={() => setAdpopind(3)}>PLACE ORDER</button>
                    </div>
                )
            }
            <div className="checkout-popup order-details bg-white rounded-brand py-4 p-2" style={{display: adpopind === 3? 'block': 'none'}}>
                <div className="check-out-summary mx-auto">
                    <div className="order-header-wrap">
                        <p className="mb-0 order-back-btn fs-5" onClick={() => setAdpopind(0)}><i className="fas fa-arrow-left"></i></p>
                        <h2 className="fs-3 body-font-bold text-center mb-0">Order Details</h2>
                    </div>
                    <p className="mb-0 text-muted fs-sm text-center">Summary of your order</p>

                    <div className="mt-4 row justify-content-center p-0 m-0">
                        {
                            cartpros?.map((pro, ind) => (
                                <div key={ind} className="col-4">
                                    <div className="border rounded-brand overflow-hidden">
                                        <img src={splitFunc(pro?.colornimage[0].url)} alt={pro.name} className="img-fluid w-100" />
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    
                    <div className="shipping-address border rounded-brand p-2 mb-4 pb-1 mt-4">
                        <p className="mb-1 fs-5 body-font-bold">Shipping Address</p>
                        <p className="mb-1 fs-sm text-capitalize">{roomno? `${roomno}, ${buildingname}, near ${landmark}, ${city} - ${pincode}, ${state}`: selectedadd}</p>
                        <p className="mb-0 text-muted">Mobile no.: <span className="body-font-medium">9082874084</span></p>
                    </div>

                    <div className="shipping-address border rounded-brand p-2 mb-4 pb-1">
                        <p className="mb-1 fs-5 body-font-bold">Payment Method</p>
                        <div className="d-flex">
                            <input type="radio" name="payment" id="card" value='card' disabled /><label htmlFor="card" className='ms-2 text-muted body-font-semibold'>Debit Card/Credit Card</label>
                        </div>
                        <div className="d-flex">
                            <input type="radio" name="payment" id="upi" value='upi' disabled /><label htmlFor="upi" className='ms-2 text-muted body-font-semibold'>UPI</label>
                        </div>
                        <div className="d-flex">
                            <input type="radio" name="payment" id="cod" value='cod' checked onChange={(e)=> console.log(e.target.value)} /><label htmlFor="cod" className='ms-2 body-font-semibold'>Cash on delivery</label>
                        </div>
                    </div>

                    <div className="checkout-div py-3 rounded-brand border px-0 overflow-hidden">
                        <div className="row mb-3 px-3">
                            <div className="col-8">
                                <p className="mb-0 body-font-semibold fs-5">Price Details</p>
                            </div>
                        </div>
                        <div className="row mb-2 px-3">
                            <div className="col-8">
                                <p className="mb-0">Total MRP</p>
                                <p className="mb-0 lh-sm text-muted fs-sm body-font-light">Included of all taxes</p>
                            </div>
                            <div className="col-4">
                                <p className="mb-0 text-end body-font-medium">₹{totalprice}</p>
                            </div>
                        </div>
                        <div className="row mb-2 px-3">
                            <div className="col-8">
                                <p className="mb-0">Subtotal</p>
                            </div>
                            <div className="col-4">
                                <p className="mb-0 text-end body-font-medium">₹{totalprice}</p>
                            </div>
                        </div>
                        <div className="row mb-2 px-3">
                            <div className="col-8">
                                <p className="mb-0">Discount</p>
                            </div>
                            <div className="col-4">
                                    <p className="mb-0 text-end body-font-medium text-success">-₹{discountprice}</p>
                            </div>
                        </div>
                        <div className="row px-3 mb-2 border-top border-bottom py-2 mt-4">
                            <div className="col-8">
                                <p className="mb-0 body-font-medium fs-5">Total</p>
                            </div>
                            <div className="col-4">
                                <p className="mb-0 text-end body-font-medium fs-5">₹{total}</p>
                            </div>
                        </div>
                        <p className="text-center text-success body-font-bold mt-3">You save ₹{discountprice} on this order.</p>
                        <div className="text-center w-100">
                            <button className="btn btn-brand-light-brown w-75 py-2 rounded-brand-sm letter-spacing body-font-light" onClick={confirmOrder}>PLACE ORDER</button>
                        </div>
                    </div>
                </div>

                {/* <button className="btn btn-dark w-100 rounded-brand-sm letter-spacing py-2 mt-5" onClick={confirmOrder}>PLACE ORDER</button> */}
            </div>
        </div>

    </div>
  )
}

export default Cart;